import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./style.scss";
import { gsap } from "gsap";

import axios from "axios";
import RequestPortfolio from "../RequestPortfolio";
import SEO from "../seo";
// import lp from "../../images/link preview-01.jpg";
import Button from "../ButtonComponent";

export default function Connect() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [isFormActive, setisFormActive] = useState(false);

  const [currentSrc, setCurrentSrc] = useState(
    "https://blackelephantdigital.com/assets/studio/4.jpg"
  );

  function toggleForm() {
    setisFormActive(!isFormActive);
  }
  useEffect(() => {
    const card = document.querySelector(`.connect__form`);
    const inputs = document.querySelectorAll(".connect__form__input");
    // const textarea = document.querySelector(".connect__form__textarea");
    // const submitbutton = document.querySelector(".connect__form .btn");
    const map = document.querySelector(".connect__map");

    var xPercent = 0;
    var yPercent = 0;
    var overinput = false;

    // card.addEventListener("mousemove", onMouseMoveGsap);
    // card.addEventListener("mouseleave", onMouseLeaveGsap);
    // card.addEventListener("mouseenter", onMouseEnterForm);

    inputs.forEach((input) => {
      input.addEventListener("mouseenter", onMouseEnterInput);
      input.addEventListener("mouseleave", onMouseLeaveInput);
    });

    // submitbutton.addEventListener("mouseenter", onMouseEnterInput);
    // submitbutton.addEventListener("mouseleave", onMouseLeaveInput);

    map.addEventListener("mouseenter", (e) => {
      document
        .querySelector(".layout__cursor")
        .classList.add("layout__cursor--hide");
    });

    map.addEventListener("mouseleave", (e) => {
      document
        .querySelector(".layout__cursor")
        .classList.remove("layout__cursor--hide");
    });

    function onMouseEnterForm(e) {
      document
        .querySelector(".layout__cursor")
        .classList.add("layout__cursor--hide");
    }

    function onMouseEnterInput(e) {
      overinput = true;
      gsap.to(`.connect__form__background`, {
        clipPath: `circle(0% at ${xPercent}% ${yPercent}%`,
        duration: 0.1,
      });
    }

    function onMouseLeaveInput(e) {
      overinput = false;
    }

    function onMouseMoveGsap(e) {
      if (!overinput) {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top; //y position within the element.

        xPercent = (x / card.clientWidth) * 100;
        yPercent = (y / card.clientHeight) * 100;

        gsap.to(".connect__form__background", {
          clipPath: `circle(10% at ${xPercent}% ${yPercent}%`,
          duration: 0.1,
        });
      }
    }

    function onMouseLeaveGsap(e) {
      gsap.to(`.connect__form__background`, {
        clipPath: `circle(0% at ${xPercent}% ${yPercent}%`,
        duration: 0.1,
      });
      document
        .querySelector(".layout__cursor")
        .classList.remove("layout__cursor--hide");
    }
  }, []);

  async function onFormSubmit(e) {
    e.preventDefault();

    setIsSubmitting(true);

    if (firstName === "") {
      setErrors({ firstName: "Please Enter Your First Name!" });
      setIsSubmitting(false);
      return;
    }

    if (lastName === "") {
      setErrors({ lastName: "Please Enter Your last Name!" });
      setIsSubmitting(false);
      return;
    }

    if (email === "") {
      setErrors({ email: "Please Enter Email Address!" });
      setIsSubmitting(false);
      return;
    }

    if (!isEmailValid(email)) {
      setErrors({ email: "Invalid Email Address!" });
      setIsSubmitting(false);
      return;
    }

    if (phone === "") {
      setErrors({ phone: "Please Enter Your Phone!" });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://blackelephantdigital.com/api/public/index.php/connect",
        { email, phone, firstName, lastName, message }
      );

      setIsSubmitting(false);
      setShowSuccess(true);
      setEmail("");
      setFirstName("");
      setLastName("");
      setMessage("");
      setPhone("");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (e) {
      console.log(e);
      if (e.response) {
        setErrors({ msg: "Internal Server Error!" });
      } else {
        setErrors({
          msg:
            "Network Error! Please check if you are connected to the internet.",
        });
        setIsSubmitting(false);

        setTimeout(() => {
          setErrors({ ...errors, msg: null });
        }, 5000);
      }
    }

    console.log(email, phone, firstName, lastName, message);
  }

  function handleFirstNameChange(e) {
    setFirstName(e.target.value);
    setErrors({ ...errors, firstName: null });
  }

  function handleLastNameChange(e) {
    setLastName(e.target.value);
    setErrors({ ...errors, lastName: null });
  }
  function handleEmailChange(e) {
    setEmail(e.target.value);
    setErrors({ ...errors, email: null });
  }
  function handlePhoneChange(e) {
    setPhone(e.target.value);
    setErrors({ ...errors, phone: null });
  }
  function handleMessageChange(e) {
    setMessage(e.target.value);
    setErrors({ ...errors, message: null });
  }

  function isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Layout>
      <SEO
        title="Connect | Digital Marketing On-Demand"
        description="Get in touch for a quote suited for your brand’s Scope of Work. Feedback and enquiries welcome. Response within 24 hours.	"
        img="https://blackelephantdigital.com/assets/linkpreviews/main.jpg"
      />
      <div className="connect">
        <RequestPortfolio isFormActive={isFormActive} closePopup={toggleForm} />
        <div className="connect__hero">
          <h1 className="connect__heading">Connect</h1>
          <h2 className="connect__toptext">
            Reach out to us today to explore how we can add value to your
            marketing and propel your brand towards business success.
          </h2>

          <div className="connect__hero__btns">
            {/* <button onClick={} className="btn-light"></button> */}

            <Button
              className="btn-light"
              text="request portfolio"
              fillColor="#3DB2FF"
              onClick={toggleForm}
            />

            <Button
              className="btn-light"
              text="schedule a meeting"
              fillColor="#C37B89"
              url="https://cal.com/black-elephant-digital/15min"
              targetBlank
              isATag
            />
          </div>
        </div>

        <form className="connect__form" onSubmit={onFormSubmit}>
          <img
            alt=""
            draggable="false"
            className="connect__form__background"
            src="https://image.freepik.com/free-vector/set-social-media-doodles_6997-1434.jpg"
          />
          <div className="connect__form__heading">Get In Touch</div>

          <div className="connect__form__group">
            <div className="connect__form__group__field">
              <label>
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="firstName"
                placeholder="Your First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                className={`connect__form__input ${
                  errors.firstName && "connect__form__input--invalid"
                }`}
              ></input>
            </div>
            <div className="connect__form__group__field">
              <label>
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="lastName"
                value={lastName}
                placeholder="Your Last Name"
                onChange={handleLastNameChange}
                className={`connect__form__input ${
                  errors.lastName && "connect__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>

          <div className="connect__form__group">
            <div className="connect__form__group__field">
              <label>
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="email"
                placeholder="Your Email Address"
                value={email}
                onChange={handleEmailChange}
                className={`connect__form__input ${
                  errors.email && "connect__form__input--invalid"
                }`}
              ></input>
            </div>
            <div className="connect__form__group__field">
              <label>
                Phone<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="phone"
                value={phone}
                placeholder="Your Phone Number"
                onChange={handlePhoneChange}
                className={`connect__form__input ${
                  errors.phone && "connect__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>
          <label>Message</label>
          <input
            name="message"
            value={message}
            className="connect__form__input"
            onChange={handleMessageChange}
            placeholder="A Message For Us"
          ></input>

          {/* <button className="btn btn-dark mt-10"></button> */}
          <div className="btn mt-10 tal">
            <Button
              className="btn-dark"
              text={isSubmiting ? "...Submitting" : "Submit"}
            />
          </div>

          {errors.phone && (
            <div className="connect__form__error">
              <small>{errors.phone}</small>
            </div>
          )}
          {errors.email && (
            <div className="connect__form__error">
              <small>{errors.email}</small>
            </div>
          )}
          {errors.firstName && (
            <div className="connect__form__error">
              <small>{errors.firstName}</small>
            </div>
          )}
          {errors.lastName && (
            <div className="connect__form__error">
              <small>{errors.lastName}</small>
            </div>
          )}

          {showSuccess && (
            <div className="connect__form__success">
              <small> Thank you. Your response have been recorded.</small>
            </div>
          )}

          {/* <div className="connect__form__meetus">Meet us here</div> */}
        </form>

        <div className="connect__ourlocation">
          {/* <img></img> */}

          <div className="row connect__ourlocation__row">
            <div className="col col-12 col-md-6">
              <h2 className="connect__heading2">BE Studios Location</h2>
              <div className="connect__ourlocation__text">
                Visit our colorful, vibrant artistic workshop and sound studio
                to meet our team and see creative magic in action.{" "}
              </div>

              <img
                className="connect__ourlocation__gal__main"
                src={currentSrc}
              ></img>
              <div className="connect__ourlocation__gal__thumbnails">
                <img
                  className="connect__ourlocation__gal__thumbnails__thumbnail"
                  src="https://blackelephantdigital.com/assets/studio/2.jpg"
                  onClick={() =>
                    setCurrentSrc(
                      "https://blackelephantdigital.com/assets/studio/2.jpg"
                    )
                  }
                ></img>

                <img
                  className="connect__ourlocation__gal__thumbnails__thumbnail"
                  src="https://blackelephantdigital.com/assets/studio/3.jpg"
                  onClick={() =>
                    setCurrentSrc(
                      "https://blackelephantdigital.com/assets/studio/3.jpg"
                    )
                  }
                ></img>
                <img
                  className="connect__ourlocation__gal__thumbnails__thumbnail"
                  src="https://blackelephantdigital.com/assets/studio/4.jpg"
                  onClick={() =>
                    setCurrentSrc(
                      "https://blackelephantdigital.com/assets/studio/4.jpg"
                    )
                  }
                ></img>
                <img
                  className="connect__ourlocation__gal__thumbnails__thumbnail"
                  src="https://blackelephantdigital.com/assets/studio/1.jpg"
                  onClick={() =>
                    setCurrentSrc(
                      "https://blackelephantdigital.com/assets/studio/1.jpg"
                    )
                  }
                ></img>
              </div>
            </div>
            <div className="col col-12 col-md-6">
              <iframe
                className="connect__map"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.7394464293125!2d77.10214471514202!3d28.487392397385765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ecd6fa292b5%3A0xc801d1cb1556f9ac!2sBlack%20Elephant%20Digital%20Studios!5e0!3m2!1sen!2sin!4v1622023082063!5m2!1sen!2sin"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Button
              url="https://blackelephantdigital.com/assets/studio/map.jpg"
              className="btn-light"
              download={"map.jpg"}
              text="download map"
              fillColor="#000"
              isATag
              targetBlank
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
